import axios from '@/plugins/axios'

/**
 * Create bank account
 */
const createBankAccount = async ({
  bank,
  agency,
  // eslint-disable-next-line camelcase
  agency_digit,
  account,
  digit,
  type,
  // eslint-disable-next-line camelcase
  owner_name,
  // eslint-disable-next-line camelcase
  owner_document,
  // eslint-disable-next-line camelcase
  owner_document_type
}) => {
  try {
    const params = {
      bank_id: bank,
      agency: agency,
      account: account,
      digit: digit,
      type: type,
      owner_name: owner_name,
      owner_document: owner_document,
      owner_document_type: owner_document_type
    }

    // eslint-disable-next-line camelcase
    if (agency_digit) params.agency_digit = agency_digit

    const response = await axios.post('bank-accounts', params)
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Get banks
 */
const getBanks = async () => {
  try {
    const response = await axios.get('banks')
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Get bank accounts
*/
const getBankAccounts = async ({ companyId }) => {
  try {
    const response = await axios.get('bank-accounts', {
      params: {
        company_id: companyId
      }
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Get primary bank account
 */
const getPrimaryBankAccount = async ({ companyId }) => {
  try {
    const accounts = await getBankAccounts({ companyId: companyId })
    return accounts.data.find(item => {
      return item.is_primary
    })
  } catch (error) {
    return Promise.reject(error)
  }
}

export default {
  createBankAccount,
  getBanks,
  getBankAccounts,
  getPrimaryBankAccount
}
