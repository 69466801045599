import axios from '@/plugins/axios'

/**
 * Create wallet checkout
 */
const createCheckout = async ({ companyId, amount }) => {
  try {
    const params = {
      company_id: companyId,
      amount: amount,
      type: 'CHECKOUT'
    }
    const response = await axios.post('wallets', params)
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Get wallets
 */
// eslint-disable-next-line camelcase
const getWallets = async ({ companyId, start_date = null, end_date = null, amountsOnly = 0, page = 1 }) => {
  try {
    const params = {
      company_id: companyId,
      page: page
    }
    // eslint-disable-next-line camelcase
    if (start_date) params.start_date = start_date
    // eslint-disable-next-line camelcase
    if (end_date) params.end_date = end_date
    if (amountsOnly) params.amounts_only = 1
    const response = await axios.get('wallets', {
      params: params
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Get only wallet amounts
 */
const getWalletAmounts = async ({ companyId }) => {
  return await getWallets({ companyId: companyId, start: '1999-01-01', end: '2100-01-01', amountsOnly: true })
}

export default {
  createCheckout,
  getWallets,
  getWalletAmounts
}
