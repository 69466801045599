<template>
  <div>
    <v-select
      class="custom-select font-size-xSmall"
      :options="options"
      @input="inputSelected"
      :placeholder="placeholder"
      v-model="local"
    >
      <template #open-indicator="{ attributes }">
        <span v-bind="attributes"><i class="fa fa-caret-down text-color2"></i></span>
      </template>
    </v-select>
  </div>
</template>

<script>

import vSelect from 'vue-select'

export default {
  name: 'Select2',
  props: ['options', 'forgettable', 'placeholder'],
  components: {
    vSelect
  },
  data () {
    return {
      local: ''
    }
  },
  methods: {
    inputSelected (e) {
      this.$emit('input', e)
      this.$emit('change', e)
      if (this.forgettable) this.local = ''
    }
  }
}
</script>

<style lang="css" src="vue-select/dist/vue-select.css"></style>

<style lang="scss" scoped>
</style>
