<template>
  <b-modal
    id="create-bank-account-modal"
    :modal-class="'default-modal'"
    :hide-footer="true"
  >
    <div class="text-center">
      <h2 class="highlighted-color1">
        <span>{{ $t('create_bank_account') }}</span>
      </h2>
    </div>
    <div class="mt-3">
      <BankAccountCreationForm
        @bank-account-created="handleCreated"
      />
    </div>
  </b-modal>
</template>

<script>

import NotificationService from '@/modules/notifications/services/notifications-service'

import BankAccountCreationForm from '@/components/bank-accounts/BankAccountCreationForm'

export default {
  name: 'CreateBankAccountModal',
  components: {
    BankAccountCreationForm
  },
  data () {
    return {
      loader: false,
      content: null,
      content_type: 'error'
    }
  },
  methods: {
    /**
     * Handle created
     */
    async handleCreated (account) {
      const notification = {
        notification: {
          type: 'success',
          content: this.$t('bank_account_created_successfully')
        }
      }
      NotificationService.createGlobalSuccessNotification(notification)
      this.$bvModal.hide('create-bank-account-modal')
      this.$emit('created-bank-account', account)
    }
  }
}
</script>
