<template>
  <div
    class="card p-3"
    :class="classes"
  >
    <div class="heading d-flex flex-column flex-md-row justify-content-between">
      <div class="text-start col-md-8 px-0">
        <span class="d-block font-weight-bold font-size-regular text-capitalize">{{ account.owner_name }}</span>
        <div>
          <span class="font-size-xSmall text-color2">{{ account.owner_document | VMask(documentMask) }}</span>
        </div>
      </div>
      <div class="col-md-4 px-0">
        <span class="font-size-xxSmall text-uppercase" v-if="1==2">{{ account.is_confirmed ? 'Confirmada' : 'Pendente confirmar' }}</span>
      </div>
    </div>
    <div class="d-flex flex-column flex-lg-row justify-content-center justify-content-lg-between mt-3 mt-lg-5">
      <div
        class="font-size-xSmall text-center text-lg-start"
      >
        <span class="d-block">{{ account.bank.name }}</span>
        <span class="d-block">{{ $t('agency') }}: {{ `${account.agency}${account.agency_digit ? '-' + account.agency_digit : ''}` }}</span>
        <span class="d-block">{{ $t('account') }}: {{ `${account.account}-${account.digit}` }}</span>
      </div>
      <div
        class="d-flex justify-content-center justify-content-lg-end align-items-center mt-3 mt-lg-0"
        v-if="editable"
      >
        <Button
          class="btn-color3 btn-sm"
          v-if="account.is_primary"
        >
          {{ $t('main') }}
        </Button>
        <template
          v-else
        >
          <!-- <Button
            class="btn-outline-color2 btn-sm"
            @click="openTurnPrimaryModal"
          >
            Tornar principal
          </Button>
          <TurnBankAccountPrimaryModal
            :account="account"
            @bank-account-turned-primary="parseBankAccountTurnedPrimary"
          /> -->
        </template>
        <!-- <Button
          class="btn-circle btn-outline-color2 d-inline-block ml-2"
          @click="openDestroyModal"
        >
          <i class="fa fa-trash"></i>
        </Button>
        <DestroyBankAccountModal
          :account="account"
          @bank-account-deleted="parseDeletedBankAccount"
        /> -->
      </div>
    </div>
  </div>
</template>

<script>

import Button from '@/components/common/Button/Button'
// import DestroyBankAccountModal from '@/components/bank-accounts/DestroyBankAccountModal'
// import TurnBankAccountPrimaryModal from '@/components/bank-accounts/TurnBankAccountPrimaryModal'
import { mask } from 'vue-the-mask'

export default {
  name: 'BankAccountCard',
  props: ['account', 'size', 'editable'],
  directives: { mask },
  components: {
    Button
    // DestroyBankAccountModal,
    // TurnBankAccountPrimaryModal
  },
  computed: {
    cardSize () {
      return this.size ? this.size : 'regular'
    },
    classes () {
      const data = []
      if (this.cardSize === 'regular') {

      } else if (this.cardSize === 'small') {
        data.push('p-4')
      }

      return data
    },
    documentMask () {
      if (this.account.owner_document_type === 'cpf') return '###.###.###-##'
      return '##.###.###/####-##'
    }
  },
  methods: {
    // openDestroyModal () {
    //   // v-b-modal.destroy-bank-account
    //   this.$bvModal.show(`destroy-bank-account-${this.account.id}`)
    // },
    // openTurnPrimaryModal () {
    //   this.$bvModal.show(`turn-bank-account-primary-${this.account.id}`)
    // },
    // parseBankAccountTurnedPrimary () {
    //   this.$emit('bank-account-turned-primary')
    // },
    // parseDeletedBankAccount () {
    //   this.$emit('bank-account-deleted')
    // }
  }
}
</script>
